import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import {
  Select,
  MenuItem,
  Box,
  Chip,
  InputLabel,
  FormControl,
  Typography,
  Tabs,
  Tab,
  Avatar,
  Radio,
  RadioGroup,
  FormControlLabel,
  Slider,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { memo } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil, showToast } from "../../utils/CommonUtil";
import { useProductsContext } from "../../Contexts/SidebarContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { makeStyles, styled } from "@mui/styles";
import ToastManager from "../../Components/ToastManager";
import ImageIcon from "@mui/icons-material/Image";
import EditIcon from "@mui/icons-material/Edit";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  selectMenu: {
    maxHeight: "160px",
  },
});
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
interface CustomNodeYesProps {
  show: boolean;
  onSaveOrCancelDialogue: Function;
  onCancelDialogue: Function;
  editNode: any;
  onUpdateNode: Function;
  sourceEdge: string;
  deleteButtonLevelEdge: Function;
}

const btnStyle = { marginTop: 10 };
function FormDialog({
  show,
  onSaveOrCancelDialogue,
  onCancelDialogue,
  editNode,
  onUpdateNode,
  sourceEdge,
  deleteButtonLevelEdge,
}: CustomNodeYesProps) {
  const isEdit = Object.keys(editNode).length > 0;
  let hours = null;
  let minutes = null;
  if (isEdit && editNode?.delayCard !== null && editNode?.delayCard > 0) {
    hours = Math.floor(editNode.delayCard / 60);
    minutes = editNode?.delayCard % 60;
  }
  let strMsg: string = editNode.textMessage;
  try {
    if (strMsg.charCodeAt(editNode.textMessage.indexOf("\n")) === 10) {
      strMsg = editNode.textMessage.split("\n").join("\\n");
    }
  } catch (e) {}
  let webUrl2: any = "";
  const [selectedRadio, setSelectedRadio] = useState("randomizer");
  const classes = useStyles();
  const initialValues = {
    message: isEdit ? editNode.textMessage : "",
    cardName: isEdit ? editNode.cardName : "",
    websiteURL: isEdit ? editNode.websiteUrl : "",
    buttonType: isEdit ? editNode.buttonType : "",
    multipleButton: "",
    checked: (isEdit && hours) || minutes ? true : false,
    hour: isEdit && hours ? hours : "",
    minutes: isEdit && minutes ? minutes : "",
    multiButtons: "",
    isChecked: isEdit && editNode.isChecked ? true : false,
    messengerlist: isEdit ? editNode.subscriberListName : "",
    subscriberName: isEdit ? editNode.subscriberListName : "",
    subscriptionTitle: isEdit ? editNode.optinTitle : "",
    ctaMessage: isEdit ? editNode.ctatext : "",
    supporterCheck: isEdit && editNode.supporterCheck ? true : false,
    detractorCheck: isEdit && editNode.detractorCheck ? true : false,
    randomizer: isEdit && editNode.randomizer ? editNode.randomizer : [],
  };
  const validationSchema = Yup.object().shape({
    isChecked: Yup.boolean(),
    message: Yup.string().test(
      "is-required",
      "Please Enter Message",
      function (value) {
        if (!this.parent.isChecked) {
          return Yup.string().min(3, "It's too short").isType(value);
        }
        return true;
      }
    ),
    cardName: Yup.string()
      .min(2, "It's too short")
      .required("Please Enter Card Name"),
    buttonType: Yup.string(),
    hour: Yup.number().max(24),
    minutes: Yup.number().max(59),
  });

  const actionFlagsValidationSchema = Yup.object().shape({
    // supporterCheck: Yup.boolean(),
    // detractorCheck: Yup.boolean(),
  });

  const { pageInfo, subscriber, setSubscriber } = useProductsContext();
  const [open, setOpen] = useState(false);
  const [multiButton, setMultiButton] = useState("");
  const [webUrl1, setWebUrl1] = useState("");
  const [buttonType, setButtonType] = useState("");
  const [selected, setSelected] = useState<any>([]);
  const [data, setData] = useState(initialValues);
  const [cardN, setCardN] = useState("");
  const [messageN, setMessageN] = useState("");

  const [newFields, setNewFields] = useState(false);
  const [saveList, setSaveList] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [optinCheck, setOptinCheck] = useState(false);
  const [delayCheck, setDelayCheck] = useState(false);

  const [messengerList, setMessengerList] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [checkedSupporter, setCheckedSupporter] = useState(false);
  const [checkedDetractor, setCheckedDetractor] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");
  const [uploadFile, setUploadFile] = useState(false);
  const [fileSize, setFileSize] = useState(0);
  const [fileName1, setFileName1] = useState("No file chosen");
  const [uploadFile1, setUploadFile1] = useState(false);
  const [fileSize1, setFileSize1] = useState(0);
  const [editIndex, setEditIndex] = useState(null);
  const [webUrlSave, setWebUrlSave] = useState(false);
  const [messengerVal, setMessengerVal] = useState("");

  const [validMessage, setValidMessage] = useState(true);
  const [variations, setVariations] = useState(initialValues.randomizer);
  const [error, setError] = useState("");
  const [totalPercentage, setTotalPercentage] = useState(100);

  useEffect(() => {
    setOpen(show);
    setSelectedTab(0);
    // setCheckedDetractor(false);
    // setCheckedSupporter(false);
    setCardN("");
    setMessageN("");
    if (isEdit) {
      if (editNode.cardName === "") {
        setSelectedTab(1);
        // if (!editNode.supporterCheck && !editNode.detractorCheck)
        //   setSelectedRadio("randomizer");
        if (editNode.randomizer) setSelectedRadio("randomizer");
      } else {
        setSelectedTab(0);
      }
      const tempSelectArry = editNode?.multipleButton;
      setSelected(tempSelectArry);
      setButtonType(editNode.buttonType);
      setWebUrl1(editNode.websiteUrl);
      setCardN(editNode.cardName);
      setMessageN(editNode.textMessage);
      setMessengerVal(editNode.subscriberListName);
      setOptinCheck(editNode.isChecked);
      setDelayCheck(editNode.checked);
      // setCheckedSupporter(editNode.supporterCheck);
      // setCheckedDetractor(editNode.detractorCheck);
      setVariations(editNode.randomizer || []);
    }
  }, [show]);
  useEffect(() => {
    if (Array.isArray(variations)) {
      const totalPer = variations.reduce(
        (acc: any, variation: any) => acc + variation.percentage,
        0
      );
      setTotalPercentage(totalPer);
      if (totalPer !== 100) {
        setError(
          `Distribution total must equal 100%, Current is ${Math.floor(
            totalPer
          )}%`
        );
        setValidMessage(false);
      } else {
        setError("");
        setValidMessage(true);
      }
    }
  }, [variations]);

  const getSubscriberList = () => {
    let params: any = "";
    let pageId = pageInfo?.pageId;
    if (pageId) {
      params = `pageId=${pageId}`;
      try {
        NetworkManager.sendJsonRequest(
          "marketingMessages/getSubscriberLists",
          "",
          params,
          "GET",
          true,
          onSuccessGetList,
          onErrorCall
        );
      } catch (error) {}
    }
  };

  const onSuccessGetList = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      setMessengerList(respData?.subscriberLists);
    }
  };

  const onSaveSubscriberName = (props: any) => {
    let params: any = "";
    let pageId = pageInfo?.pageId;
    if (pageId) {
      params = `pageId=${pageId}`;
      const reqData = {
        subscriberListName: props.values.subscriberName,
        optinTrigger: "private_replies",
        optinTitle: props.values.subscriptionTitle,
        ctatext: props.values.ctaMessage,
      };
      try {
        NetworkManager.sendJsonRequest(
          "marketingMessages/createSubscriberList",
          reqData,
          params,
          "POST",
          true,
          onSuccessSaveList,
          onErrorCall
        );
      } catch (error) {}
    }
  };
  const onSuccessSaveList = (respData: any) => {
    if (respData) {
      setMessengerList(respData?.subscriberLists);
      setNewFields(false);
      getSubscriberList();
      ToastManager.showToast(
        "SubscriberListName Added Successfully.",
        "Success"
      );
    } else if (respData.statusCode === "MKM0009") {
      ToastManager.showToast("SubscriberListName already exists!", "Error");
    } else if (respData.statusCode === "MKM0008") {
      ToastManager.showToast(
        "SubscriberListDetails fields cannot be empty!",
        "Error"
      );
    }
  };
  const onErrorCall = (err: any) => {
    console.log(err);
  };
  const handleDelete = (i: any, value: any) => {
    setWebUrl1("");
    setWebUrlSave(false);
    let temparr = [...selected];
    temparr.splice(i, 1);
    setSelected(temparr);
    if (isEdit) deleteButtonLevelEdge(value, [editNode]);
  };
  const handleEdit = (i: any, value: any) => {
    if (isEdit) {
      setWebUrlSave(true);
      setEditIndex(i);
      setWebUrl1(value.webUrl);
    }
  };

  const onClickMultiAddButton = () => {
    let id: any = null;
    let isExceededCount: any = false;

    if (
      selected?.length > 0 &&
      selected[selected.length - 1].buttonType !== null
    ) {
      let idNum = parseInt(selected[selected.length - 1].id.substr(-1));

      const getButtonTypesList = selected.filter((selBtn: any) => {
        return (
          selBtn.buttonType === "webUrlButton" ||
          selBtn.buttonType === "postback"
        );
      });

      const getButtonQuickReplies = selected.filter((selBtn: any) => {
        return selBtn.buttonType === "QUICK_REPLIES";
      });
      if (getButtonTypesList != null && getButtonTypesList.length < 3) {
        idNum += 1;
        id = `${buttonType}${idNum}`;
      } else if (
        getButtonQuickReplies != null &&
        getButtonQuickReplies.length > 0 &&
        getButtonQuickReplies.length < 13
      ) {
        idNum += 1;
        id = `${buttonType}${idNum}`;
      } else if (
        getButtonQuickReplies != null &&
        getButtonQuickReplies.length === 0 &&
        buttonType !== "postback" &&
        buttonType !== "webUrlButton"
      ) {
        idNum += 1;
        id = `${buttonType}${idNum}`;
      } else {
        isExceededCount = true;
      }
    } else {
      id = `${buttonType}0`;
    }
    if (!isExceededCount) {
      setSelected((selected: any) => [
        ...selected,
        {
          id: id,
          multiButton: multiButton,
          buttonType: buttonType,
          webUrl: webUrl1,
        },
      ]);
    }

    setMultiButton("");
    setWebUrl1("");
  };
  const handleClose = () => {
    onCancelDialogue();
    setMultiButton("");
    setSelected([]);
    setButtonType("");
    setWebUrl1("");
    setNewFields(false);
    setWebUrlSave(false);
    setVariations([]);
  };
  const onHandleSave = (value: any) => {
    const updatedData = { ...data, ...value, multipleButton: selected };

    if (isEdit) {
      const nodeInfo = { ...updatedData, label: editNode.label };
      onUpdateNode(nodeInfo);
    } else onSaveOrCancelDialogue(updatedData);
    setData((data) => ({
      ...data,
      ...value,
      multipleButton: selected,
    }));

    setMultiButton("");
    setSelected([]);
    setButtonType("");
    setWebUrl1("");
    setCardN("");
    setMessageN("");
    setMessengerVal("");
    setCheckedDetractor(false);
    setCheckedSupporter(false);
    setVariations([]);
    setOptinCheck(false);
    setDelayCheck(false);
    console.log(data);
  };
  const handleSaveEdit = () => {
    if (editIndex !== null) {
      const updatedSelected = [...selected];
      updatedSelected[editIndex].webUrl = webUrl1;
      setSelected(updatedSelected);
      setEditIndex(null);
      //  setWebUrl1("");
      ToastManager.showToast("URL Updated Successfully.", "Success");
    }
  };

  const handleOnMessageUpdate = (e: any) => {
    setMultiButton(e.target.value);
  };

  const handleOnWebUrlChange = (e: any) => {
    setWebUrl1(e.target.value);
  };
  const onSubmit = (values: any, props: any) => {
    if (
      selectedTab === 1 &&
      selectedRadio === "randomizer" &&
      totalPercentage !== 100
    ) {
      ToastManager.showToast(
        "Total Percentage should be equal to 100",
        "Error"
      );
      return;
    }
    let newTempData: any = {
      ...values,
      // supporterCheck:
      //   selectedTab === 0 && selectedRadio === "action"
      //     ? false
      //     : checkedSupporter,
      // detractorCheck:
      //   selectedTab === 0 && selectedRadio === "action"
      //     ? false
      //     : checkedDetractor,
      randomizer:
        selectedTab === 1 && selectedRadio === "randomizer" ? variations : [],
    };
    try {
      newTempData.message = newTempData.message
        .split("\\n")
        .join(String.fromCharCode(10));
    } catch (e) {}
    onHandleSave(newTempData);
    setWebUrlSave(false);
    if (selectedTab === 0) {
      props.resetForm();
      setNewFields(false);
    }
  };
  const selectedConnection: any = localStorage.getItem("selectedConnection");
  const isTypeExists = (type: string) => {
    if (selectedConnection === "nav-fb-tab") {
      return false;
    } else
      return (
        selected.findIndex((selBtn: any) => selBtn.buttonType === type) !== -1
      );
  };

  const onOpenNewFields = (props: any) => {
    setNewFields(true);
    setSaveList(true);
    setOpenList(false);
    props.setFieldValue("messengerlist", "");
    props.setFieldValue("subscriberName", "");
    props.setFieldValue("subscriptionTitle", "");
    props.setFieldValue("ctaMessage", "");
    setDisableFields(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const switchTab = (tab: any) => {
    if (selectedTab === 0) {
      // setSelectedRadio("randomizer");
      setVariations([]);
      setCheckedSupporter(false);
      setCheckedDetractor(false);
      setTotalPercentage(0);
    } else if (selectedTab === 1) {
      setCardN("");
      setMessageN("");
      setMultiButton("");
      setSelected([]);
      setButtonType("");
      setWebUrl1("");
      setOptinCheck(false);
      setDelayCheck(false);
      setMessengerVal("");
    }
  };
  const handleChangeRadio = (e: any) => {
    setSelectedRadio(e.target.value);
    if (selectedRadio === "action") {
      setVariations([]);
      setTotalPercentage(0);
    } else if (selectedRadio === "randomizer") {
      setCheckedDetractor(false);
      setCheckedSupporter(false);
    }
  };

  const handleKeyPress = (e: any, props: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const target = e.target as HTMLTextAreaElement;
      const cursorPosition = target.selectionStart;
      const newValue = `${target.value.slice(
        0,
        cursorPosition
      )}\n${target.value.slice(cursorPosition)}`;
      setMessageN(newValue);
      props.setFieldValue("message", newValue);
    }
  };
  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setFileSize(file.size);
    } else {
      setFileName("No file chosen");
    }
  };
  const onClickUpload = () => {
    setUploadFile(true);
  };
  const handleFileChangeNew = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileName1(file.name);
      setFileSize1(file.size);
    } else {
      setFileName1("No file chosen");
    }
  };
  const onClickUploadNew = () => {
    setUploadFile1(true);
  };
  const handleAddVariation = () => {
    const nextIndex = variations.length;
    if (nextIndex >= 6) return;
    const nextId = `${String.fromCharCode(65 + nextIndex)}${nextIndex + 1}`;

    const newVariation = {
      id: nextId,
      name: `${String.fromCharCode(65 + nextIndex)}`,
      percentage: 100 / (nextIndex + 1),
    };
    // For Decimal Percentage
    const remaining = 100 - Math.floor(100 / (nextIndex + 1)) * (nextIndex + 1);
    const updatedVariations = variations.map((item: any) => ({
      ...item,
      percentage:
        Math.floor(100 / (nextIndex + 1)) + (item < remaining ? 1 : 0),
    }));
    setVariations([...updatedVariations, newVariation]);
  };
  const handleRemoveVariation = (index: any) => {
    const updatedVariations = variations
      .filter((_: any, i: any) => i !== index)
      .map((variation: any, id: any) => ({
        ...variation,
        name: String.fromCharCode(65 + id),
      }));
    const length = updatedVariations.length;
    const basePercentage = Math.floor(100 / length);
    const remaining = 100 - basePercentage * length;

    const adjustedVariations = updatedVariations.map(
      (variation: any, id: any) => ({
        ...variation,
        percentage: basePercentage + (id < remaining ? 1 : 0),
      })
    );
    setVariations(adjustedVariations);
  };

  const handleSliderChange = (index: any, newValue: any) => {
    const updatedVariations = [...variations];
    updatedVariations[index].percentage = Math.floor(newValue);
    setVariations(updatedVariations);
  };

  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "40%",
            maxHeight: "70%",
          },
        }}
      >
        {subscriber === "subscriber" ? (
          ""
        ) : (
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="tabs"
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            sx={{
              "& .Mui-selected": {
                bgcolor: "#1976d2",
                color: "#ffffff !important",
              },
              "& .MuiTab-root:not(.Mui-selected)": {
                border: "1px solid #ccc",
                borderColor: "#ccc",
              },
            }}
          >
            <Tab
              label="Card Info"
              onClick={() => switchTab(0)}
              sx={{ textTransform: "none", fontSize: "16px" }}
              disabled={
                isEdit && selectedRadio === "randomizer" && totalPercentage > 0
                  ? true
                  : false
              }
            />
            <Tab
              // label="Action Flags / AB Test"
              label="A/B Test"
              // disabled={sourceEdge === "nextStep" ? true : false}
              onClick={() => switchTab(1)}
              sx={{ textTransform: "none", fontSize: "16px" }}
            />
          </Tabs>
        )}
        <DialogTitle>
          {selectedTab === 0 ? (
            "Card Information"
          ) : (
            <FormControl sx={{ ml: 1.5 }}>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={selectedRadio}
                onChange={handleChangeRadio}
              >
                <FormControlLabel
                  value="randomizer"
                  control={<Radio />}
                  label="A/B Test"
                  // sx={{ ml: 3 }}
                />
                <FormControlLabel
                  value="action"
                  control={<Radio />}
                  label="Action"
                  sx={{ ml: 3, display: "none" }}
                  disabled
                  // disabled={
                  //   isEdit && selectedRadio === "randomizer" ? true : false
                  // }
                />
              </RadioGroup>
            </FormControl>
          )}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={
              selectedTab === 0 ? validationSchema : actionFlagsValidationSchema
            }
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form>
                {selectedTab === 0 && (
                  <Box>
                    <Field
                      as={TextField}
                      name="cardName"
                      label="Card Name"
                      autoComplete="off"
                      fullWidth
                      error={props.errors.cardName && props.touched.cardName}
                      helpertext={<ErrorMessage name="cardName" />}
                      required
                      size="small"
                      sx={{
                        height: 40,
                        marginTop: "1vh",
                      }}
                      value={cardN}
                      onChange={(e: any) => {
                        setCardN(e.target.value);
                        props.setFieldValue("cardName", e.target.value);
                      }}
                    />
                    <ErrorMessage name="cardName" />
                    {props.values.isChecked ? null : (
                      <Box>
                        <Field
                          as={TextField}
                          name="message"
                          label="Message"
                          autoComplete="off"
                          fullWidth
                          error={props.errors.message && props.touched.message}
                          //   helpertext={<ErrorMessage name="message" />}
                          required
                          multiline
                          size="small"
                          sx={{
                            height: "auto",
                            marginTop: "2vh",
                            marginBottom: 0.5,
                          }}
                          inputProps={{
                            maxLength: buttonType !== "" ? 640 : 2000,
                          }}
                          helperText={
                            buttonType !== ""
                              ? `Message must be less than 640 characters long (${props.values.message.length}/640)`
                              : `Message must be less than 2000 characters long (${props.values.message.length}/2000)`
                          }
                          value={messageN}
                          onChange={(e: any) => {
                            const newMessage = e.target.value;
                            setMessageN(newMessage);
                            props.setFieldValue("message", newMessage);
                          }}
                          onKeyPress={(
                            e: React.KeyboardEvent<HTMLTextAreaElement>
                          ) => handleKeyPress(e, props)}
                        />
                        {/* {props.errors.message && props.touched.message && (
                 <p>{props.errors.message}</p>
                )}  */}
                        <ErrorMessage name="message" />
                        <div style={{ height: 45, marginTop: "1vh" }}>
                          <FormControl fullWidth>
                            <InputLabel>Button Type</InputLabel>
                            <Select
                              // as={Select}
                              name="buttonType"
                              label="Button Type"
                              size="small"
                              onChange={(e: any) => {
                                setButtonType(e.target.value);
                                props.setFieldValue(
                                  "buttonType",
                                  e.target.value
                                );
                              }}
                              value={
                                props.values.buttonType !== ""
                                  ? props.values.buttonType
                                  : ""
                              }
                              sx={{
                                height: 45,
                              }}
                            >
                              <MenuItem value={""}>Select</MenuItem>
                              <MenuItem
                                value={"postback"}
                                disabled={isTypeExists("QUICK_REPLIES")}
                              >
                                Postback
                              </MenuItem>
                              <MenuItem
                                value={"webUrlButton"}
                                disabled={isTypeExists("QUICK_REPLIES")}
                              >
                                Web URL
                              </MenuItem>
                              <MenuItem
                                value={"QUICK_REPLIES"}
                                disabled={
                                  isTypeExists("webUrlButton") ||
                                  isTypeExists("postback")
                                }
                              >
                                Quick Replies
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              display: `${
                                buttonType === "webUrlButton" || webUrlSave
                                  ? "block"
                                  : "none"
                              }`,
                              width: "100%",
                            }}
                          >
                            <Field
                              as={TextField}
                              name="websiteURL"
                              label={webUrlSave ? "" : "Website URL"}
                              fullWidth
                              error={
                                props.errors.websiteURL &&
                                props.touched.websiteURL
                              }
                              helpertext={<ErrorMessage name="websiteURL" />}
                              // required
                              size="small"
                              sx={{
                                height: 40,
                                marginTop: "2vh",
                              }}
                              value={webUrl1}
                              onChange={handleOnWebUrlChange}
                            />
                          </div>
                          {webUrlSave ? (
                            <Button
                              sx={{ mt: 2, width: "5%" }}
                              onClick={handleSaveEdit}
                            >
                              Update
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "2vh",
                          }}
                        >
                          <Field
                            as={TextField}
                            name="multiButtons"
                            label="Multiple Button"
                            autoComplete="off"
                            fullWidth
                            error={
                              props.values.buttonType !== ""
                                ? multiButton === "" &&
                                  multiButton.trim() === ""
                                  ? true
                                  : false
                                : false
                            }
                            helpertext={<ErrorMessage name="multiButtons" />}
                            //   required={isEdit ? false : true}
                            size="small"
                            value={multiButton !== "" ? multiButton : ""}
                            onChange={handleOnMessageUpdate}
                            sx={{
                              height: 40,
                            }}
                          />
                          <button
                            type="button"
                            className="plus alt"
                            onClick={onClickMultiAddButton}
                            disabled={
                              buttonType !== "" &&
                              multiButton !== "" &&
                              multiButton.trim() !== ""
                                ? false
                                : true
                            }
                          >
                            +
                          </button>
                        </div>

                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                            marginTop: "1.5vh",
                          }}
                        >
                          {selected.map((value: any, i: any) => (
                            <Chip
                              key={i}
                              label={value.multiButton}
                              avatar={
                                <Avatar
                                  sx={{
                                    display:
                                      value.buttonType === "webUrlButton"
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <EditIcon
                                    fontSize="small"
                                    onClick={() => handleEdit(i, value)}
                                  />
                                </Avatar>
                              }
                              onDelete={() => handleDelete(i, value)}
                              deleteIcon={<DeleteIcon />}
                              variant="outlined"
                              color={
                                value.buttonType === "postback"
                                  ? "primary"
                                  : value.buttonType === "webUrlButton"
                                  ? "secondary"
                                  : "success"
                              }
                            />
                          ))}
                        </Box>
                        {buttonType !== "" ? (
                          <div
                            style={{
                              display: "flex",
                              columnGap: "10px",
                              justifyContent: "center",
                              marginTop: "15px",
                            }}
                          >
                            {" "}
                            <Typography color={"primary"}>Postback</Typography>
                            <Typography color={"secondary"}>Web URL</Typography>
                            <Typography
                              sx={{ color: "rgba(46, 125, 50, 0.7)" }}
                            >
                              Quick Reply
                            </Typography>
                          </div>
                        ) : (
                          ""
                        )}
                      </Box>
                    )}
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                          marginTop: "0.5vh",
                        }}
                      >
                        <Field
                          as={Checkbox}
                          label="Delay Card?"
                          type="checkbox"
                          name="checked"
                          checked={delayCheck}
                          onChange={(e: any) => {
                            setDelayCheck(e.target.checked);
                            props.setFieldValue("checked", e.target.checked);
                          }}
                          disabled={sourceEdge === "nextStep" ? false : true}
                        />
                        <InputLabel sx={{ marginTop: "1.5vh" }}>
                          Delay Card?
                        </InputLabel>
                      </Box>
                      {selectedConnection === "nav-fb-tab" ? (
                        subscriber == "subscriber" ? (
                          ""
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                              marginTop: "0.5vh",
                              marginLeft: "50px",
                            }}
                          >
                            <Field
                              as={Checkbox}
                              label="Include Opt-in"
                              type="checkbox"
                              name="isChecked"
                              checked={optinCheck}
                              onChange={(e: any) => {
                                setOptinCheck(e.target.checked);
                                props.setFieldValue(
                                  "isChecked",
                                  e.target.checked
                                );
                              }}
                              // disabled={sourceEdge === "nextStep" ? false : true}
                            />
                            <InputLabel sx={{ marginTop: "1.5vh" }}>
                              Include Opt-in
                            </InputLabel>
                          </Box>
                        )
                      ) : (
                        ""
                      )}
                    </Box>
                    {props.values.checked ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                          marginTop: "0.5vh",
                        }}
                      >
                        <Field
                          as={TextField}
                          name="hour"
                          label="Hours"
                          size="small"
                          type="number"
                          inputProps={{
                            max: 24,
                            min: 0,
                          }}
                          helpertext={<ErrorMessage name="hour" />}
                          sx={{
                            height: 40,

                            width: "40%",
                          }}
                        />
                        <ErrorMessage name="hour" />
                        <Field
                          as={TextField}
                          name="minutes"
                          label="Minutes"
                          size="small"
                          sx={{
                            height: 40,

                            width: "40%",
                          }}
                          inputProps={{
                            min: 1,
                            max: 59,
                          }}
                          type="number"
                          helpertext={<ErrorMessage name="minutes" />}
                        />
                        <ErrorMessage name="minutes" />
                      </Box>
                    ) : null}
                    <Box sx={{ mt: 0.5 }}>
                      {/* <Box className="image-upload">
                        <Box
                          sx={{
                            display: props.values.isChecked ? "none" : "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            component="label"
                            variant="contained"
                            size="small"
                            startIcon={<ImageIcon />}
                            sx={{
                              textTransform: "none",
                              backgroundColor: "#4aa5dc",
                              "&:hover": {
                                backgroundColor: "#4aa5dc",
                              },
                              width: "120px",
                            }}
                          >
                            Choose file
                            <VisuallyHiddenInput
                              type="file"
                              onChange={handleFileChange}
                            />
                          </Button>
                          <InputLabel
                            sx={{
                              marginTop: "1.5vh",
                              ml: 2,
                              fontSize: "14px",
                            }}
                          >
                            {fileName}
                          </InputLabel>
                        </Box>
                        <Box>
                          <Button
                            component="label"
                            variant="outlined"
                            size="small"
                            sx={{
                              ml: 10,
                              textTransform: "none",
                              color: "#4aa5dc",
                              padding: "4px 25px",
                              display: props.values.isChecked
                                ? "none"
                                : "block",
                            }}
                            onClick={onClickUpload}
                            disabled={
                              fileName === "No file chosen" ? true : false
                            }
                          >
                            Upload
                          </Button>
                        </Box>
                      </Box> */}
                      <Box>
                        {uploadFile && (
                          <Box className="upload-success">
                            <Typography
                              sx={{
                                color: fileSize > 7 * 1024 ? "red" : "green",
                              }}
                            >
                              {fileSize > 7 * 1024
                                ? "File is too large! (It should be less than 7KB)"
                                : `Uploaded the Image Successfully: ${fileName}`}
                            </Typography>
                            <Typography
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                setUploadFile(false);
                                setFileName("No file chosen");
                              }}
                            >
                              x
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {props.values.isChecked ? (
                      <Box>
                        <Box>
                          <div style={{ height: 40, marginTop: "2vh" }}>
                            <FormControl fullWidth>
                              <InputLabel>Messenger List</InputLabel>
                              <Select
                                // as={Select}
                                name="messengerlist"
                                label="Messenger List"
                                size="small"
                                open={openList}
                                onClose={() => setOpenList(false)}
                                onOpen={() => {
                                  if (subscriber !== "subscriber") {
                                    getSubscriberList();
                                  }
                                  setOpenList(true);
                                }}
                                onChange={(e: any) => {
                                  // props.setFieldValue("messengerlist", e.target.value);
                                  const selectedValue = e.target.value;
                                  const selectedObject = messengerList.find(
                                    (item: any) =>
                                      item.subscriberListName === selectedValue
                                  );
                                  if (selectedObject) {
                                    props.setFieldValue(
                                      "messengerlist",
                                      selectedValue
                                    );
                                    setMessengerVal(selectedValue);
                                    setNewFields(true);
                                    setSaveList(false);
                                    setOpenList(false);
                                    props.setFieldValue(
                                      "subscriberName",
                                      selectedObject.subscriberListName
                                    );
                                    props.setFieldValue(
                                      "subscriptionTitle",
                                      selectedObject.optinTitle
                                    );
                                    props.setFieldValue(
                                      "ctaMessage",
                                      selectedObject.ctatext
                                    );
                                    setDisableFields(true);
                                  }
                                  if (isEdit) setNewFields(true);
                                }}
                                // value={
                                //   props.values.messengerlist !== ""
                                //     ? props.values.messengerlist
                                //     : ""
                                // }
                                value={messengerVal}
                                MenuProps={{
                                  classes: { paper: classes.selectMenu },
                                }}
                                sx={{
                                  height: 45,
                                }}
                              >
                                {messengerList?.map((item: any, index: any) => (
                                  <MenuItem
                                    key={index}
                                    value={item?.subscriberListName}
                                  >
                                    {item?.subscriberListName}
                                  </MenuItem>
                                ))}
                                <hr />
                                <MenuItem
                                  sx={{ color: "#1976d2", ml: 28 }}
                                  onClick={() => onOpenNewFields(props)}
                                >
                                  <AddCircleOutlineIcon
                                    fontSize="small"
                                    sx={{ mr: 0.5 }}
                                  />
                                  Create
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </Box>

                        {newFields ? (
                          <Box>
                            <Box>
                              <Field
                                as={TextField}
                                name="subscriberName"
                                label="Subscriber Name"
                                autoComplete="off"
                                fullWidth
                                disabled={disableFields}
                                error={
                                  props.errors.subscriberName &&
                                  props.touched.subscriberName
                                }
                                helpertext={
                                  <ErrorMessage name="subscriberName" />
                                }
                                required
                                size="small"
                                sx={{
                                  height: 45,
                                  marginTop: "2vh",
                                }}
                              />
                              {/* <ErrorMessage name="subscriberName" /> */}
                            </Box>
                            <Box>
                              <Field
                                as={TextField}
                                name="subscriptionTitle"
                                label="Subscription Title"
                                autoComplete="off"
                                fullWidth
                                disabled={disableFields}
                                error={
                                  props.errors.subscriptionTitle &&
                                  props.touched.subscriptionTitle
                                }
                                helpertext={
                                  <ErrorMessage name="subscriptionTitle" />
                                }
                                required
                                size="small"
                                sx={{
                                  height: 45,
                                  marginTop: "2vh",
                                }}
                              />
                              {/* <ErrorMessage name="subscriptionTitle" /> */}
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <div
                                style={{
                                  height: 45,
                                  marginTop: "2vh",
                                  width: saveList ? "80%" : "100%",
                                }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel>CTA Messages</InputLabel>
                                  <Select
                                    // as={Select}
                                    name="ctaMessage"
                                    label="CTA Message"
                                    size="small"
                                    disabled={disableFields}
                                    onChange={(e: any) => {
                                      props.setFieldValue(
                                        "ctaMessage",
                                        e.target.value
                                      );
                                    }}
                                    value={
                                      props.values.ctaMessage !== ""
                                        ? props.values.ctaMessage
                                        : ""
                                    }
                                    sx={{
                                      height: 45,
                                    }}
                                  >
                                    <MenuItem value="GET_UPDATES">
                                      Get updates
                                    </MenuItem>
                                    <MenuItem value="ALLOW">
                                      Allow messages
                                    </MenuItem>
                                    <MenuItem value="GET">
                                      Get messages
                                    </MenuItem>
                                    <MenuItem value="OPT_IN">
                                      Opt in to messages
                                    </MenuItem>
                                    <MenuItem value="SIGN_UP">
                                      Sign up for messages
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              {saveList ? (
                                <div
                                  style={{
                                    width: "20%",
                                    marginTop: "16px",
                                    marginLeft: "25px",
                                  }}
                                >
                                  <Button
                                    onClick={() => onSaveSubscriberName(props)}
                                  >
                                    Save List
                                  </Button>
                                </div>
                              ) : null}
                            </Box>
                            {/* <Box
                              className="image-upload"
                              sx={{ marginTop: "2vh" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // ml: 5,
                                }}
                              >
                                <Button
                                  component="label"
                                  variant="contained"
                                  size="small"
                                  startIcon={<ImageIcon />}
                                  sx={{
                                    textTransform: "none",
                                    backgroundColor: "#4aa5dc",
                                    "&:hover": {
                                      backgroundColor: "#4aa5dc",
                                    },
                                    width: "120px",
                                  }}
                                >
                                  Choose file
                                  <VisuallyHiddenInput
                                    type="file"
                                    onChange={handleFileChangeNew}
                                  />
                                </Button>
                                <InputLabel
                                  sx={{
                                    marginTop: "1.5vh",
                                    ml: 2,
                                    fontSize: "14px",
                                  }}
                                >
                                  {fileName1}
                                </InputLabel>
                              </Box>
                              <Box>
                                <Button
                                  component="label"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    ml: 10,
                                    textTransform: "none",
                                    color: "#4aa5dc",
                                    padding: "4px 25px",
                                  }}
                                  onClick={onClickUploadNew}
                                  disabled={
                                    fileName1 === "No file chosen"
                                      ? true
                                      : false
                                  }
                                >
                                  Upload
                                </Button>
                              </Box>
                            </Box> */}
                            {uploadFile1 && (
                              <Box className="upload-success">
                                <Typography
                                  sx={{
                                    color:
                                      fileSize1 > 7 * 1024 ? "red" : "green",
                                  }}
                                >
                                  {fileSize1 > 7 * 1024
                                    ? "File is too large! (It should be less than 7KB)"
                                    : `Uploaded the Image Successfully: ${fileName1}`}
                                </Typography>
                                <Typography
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setUploadFile1(false);
                                    setFileName1("No file chosen");
                                  }}
                                >
                                  x
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        ) : null}
                      </Box>
                    ) : null}
                    <div
                      style={{
                        padding: "4px",
                        display: `${
                          buttonType === "QUICK_REPLIES" ? "none" : "block"
                        }`,
                      }}
                    ></div>
                  </Box>
                )}
                {selectedTab === 1 && (
                  <div>
                    {selectedRadio === "randomizer" && (
                      <Box>
                        <Box sx={{ ml: 1.5 }}>
                          {validMessage && (
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Split the traffic{" "}
                              <span style={{ fontWeight: 400 }}>
                                (Distribution total must equal 100%)
                              </span>
                            </Typography>
                          )}
                          {totalPercentage !== 100 ? (
                            <Typography sx={{ color: "#ff0000" }}>
                              {error}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                            marginTop: "1vh",
                          }}
                        >
                          <Field
                            as={Checkbox}
                            type="checkbox"
                            name="supporterCheck"
                            checked
                          />
                          <InputLabel sx={{ marginTop: "1vh" }}>
                            Random path every time{" "}
                            <HelpOutlineIcon fontSize="small" />
                          </InputLabel>
                        </Box>
                        <Box>
                          {variations.map((item: any, index: any) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                ml: 1.5,
                                p: 0.75,
                              }}
                            >
                              <Typography>{item.name}</Typography>
                              <Slider
                                value={Math.floor(item.percentage)}
                                onChange={(e: any, newValue: any) =>
                                  handleSliderChange(index, newValue)
                                }
                                aria-labelledby={`variation-${item.name}`}
                                valueLabelDisplay="auto"
                                step={1}
                                min={0}
                                max={100}
                                sx={{ mx: 2, flexGrow: 1 }}
                              />
                              <Typography>
                                {Math.floor(item.percentage)}%
                              </Typography>
                              <IconButton
                                aria-label="delete"
                                onClick={() => handleRemoveVariation(index)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                        <Box sx={{ textAlign: "center" }}>
                          {
                            <Button
                              variant="contained"
                              onClick={handleAddVariation}
                              sx={{ mt: 2 }}
                              disabled={variations.length >= 6 ? true : false}
                            >
                              + New Variation
                            </Button>
                          }
                        </Box>
                      </Box>
                    )}
                    {selectedRadio === "action" && (
                      <Box sx={{ height: "180px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                            marginTop: "1vh",
                          }}
                        >
                          <Field
                            as={Checkbox}
                            label="Supporter"
                            type="checkbox"
                            name="supporterCheck"
                            // disabled={sourceEdge === "nextStep" ? true : false}
                            checked={checkedSupporter}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setCheckedSupporter(true);
                                setCheckedDetractor(false);
                              } else {
                                setCheckedSupporter(false);
                              }
                            }}
                          />
                          <InputLabel
                            sx={{ marginTop: "1.5vh", fontSize: "18px" }}
                          >
                            Supporter
                          </InputLabel>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                            marginTop: "1vh",
                          }}
                        >
                          <Field
                            as={Checkbox}
                            label="Detractor"
                            type="checkbox"
                            name="detractorCheck"
                            // disabled={sourceEdge === "nextStep" ? true : false}
                            checked={checkedDetractor}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setCheckedDetractor(true);
                                setCheckedSupporter(false);
                              } else {
                                setCheckedDetractor(false);
                              }
                            }}
                          />
                          <InputLabel
                            sx={{ marginTop: "1.5vh", fontSize: "18px" }}
                          >
                            Detractor
                          </InputLabel>
                        </Box>
                      </Box>
                    )}
                  </div>
                )}
                <Button type="submit" style={btnStyle}>
                  {isEdit ? "Update" : "Save"}
                </Button>
                <Button style={btnStyle} onClick={handleClose}>
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default memo(FormDialog);

//onClick={onSubmit}
