import React, { useEffect, useState } from "react";
import { useProductsContext } from "../../Contexts/SidebarContext";
import ScheduledDataTable from "./ScheduledDataTable";
import HistoryDataTable from "./HistoryDataTable";
import { useNavigate, useParams } from "react-router-dom";
import { SideMenuList } from "../../utils/constants";
import ChatBotTool from "../chatbottool";
import { CommonUtil } from "../../utils/CommonUtil";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import { Tab, Tabs } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import HistoryIcon from "@mui/icons-material/History";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tabStyle: {
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    padding: "6px 20px !important",
    minHeight: "35px !important",
    marginTop: "8px !important",
  },
}));

const SubscribersPage = () => {
  let pageId: string = "";
  let pageName: any = null;
  let fbAccountId: any = null;

  const [optinTemplateList, setOptinTemplateList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.facebook;

    pageId = recFbPageInfp?.fbPageId;
    pageName = recFbPageInfp?.pageName;
    fbAccountId = recFbPageInfp?.fbAccountId;
    if (pageId) {
      let pageDataReq = "pageId=" + pageId;
      try {
        NetworkManager.sendJsonRequest(
          "getOptinTemplatesbyPageId",
          "",
          pageDataReq,
          "GET",
          true,
          onSuccessGetList,
          onErrorCall
        );
      } catch (error) {}
      savePageIdAndName(pageId, pageName, fbAccountId);
    }
  }, []);

  const onSuccessGetList = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      if (respData.statusCode === "Template2000") {
        const optinTemplateList = respData.optinTemplateDetailsList;
        setOptinTemplateList(optinTemplateList);
      }
      // else if (respData.statusCode === "Template1105") {
      //   ToastManager.showToast("No Optin templates for the pageId", "Error");
      // }
    }
  };
  const onErrorCall = (err: any) => {
    console.log(err);
  };

  const { sideBar, savePageIdAndName, pageInfo, setSubscriber } =
    useProductsContext();

  const navigate = useNavigate();

  const handleMenu = () => {
    setSubscriber("subscriber");
    navigate(`/dashboard/chatbot`, { state: { create: true, from: "fb" } });
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <div
        className="content-page"
        style={{
          marginLeft: sideBar ? "230px" : "120px",
          backgroundColor: "#fff",
        }}
      >
        <div className="content">
          <div className="container-fluid">
            <div className="row"></div>
            <div className="sec-wrapper">
              <div className="d-flex align-items-center justify-content-between mb-3 mt-2">
                <div>
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="tabs"
                    variant="fullWidth"
                    sx={{
                      "& .Mui-selected": {
                        bgcolor: "#4aa5dc",
                        color: "#ffffff !important",
                        borderRadius: "50rem",
                      },
                      "& .MuiTab-root:not(.Mui-selected)": {
                        border: "1px solid #ccc",
                        borderColor: "#ccc",
                        borderRadius: "50rem",
                      },
                      "& .MuiTabs-indicator": {
                        width: "0px !important",
                      },
                    }}
                  >
                    <Tab
                      icon={
                        <ScheduleIcon
                          fontSize="small"
                          sx={{ width: "18px", height: "18px" }}
                        />
                      }
                      iconPosition="start"
                      label="Scheduled"
                      className={classes.tabStyle}
                    />
                    <Tab
                      icon={
                        <HistoryIcon
                          fontSize="small"
                          sx={{ width: "18px", height: "18px" }}
                        />
                      }
                      iconPosition="start"
                      label="History"
                      className={classes.tabStyle}
                    />
                  </Tabs>
                </div>
                <div className="text-end">
                  <button
                    type="button"
                    onClick={handleMenu}
                    className="btn btn-primary rounded-pill new-chat"
                  >
                    <img
                      src="../assets/images/chatbot.svg"
                      className="img-fluid"
                      alt=""
                    />
                    New Subscriber Template
                  </button>
                </div>
              </div>
              {selectedTab === 0 && (
                <div className="tablerounededCorner-subscriber table-responsive">
                  <ScheduledDataTable
                    rows={optinTemplateList}
                    setOptinTemplateList={setOptinTemplateList}
                  />
                </div>
              )}
              {selectedTab === 1 && (
                <div className="tablerounededCorner-subscriber table-responsive">
                  <HistoryDataTable
                    rows={optinTemplateList}
                    setOptinTemplateList={setOptinTemplateList}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SubscribersPage;
