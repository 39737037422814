import React from "react";
import Footer from "../Menu/Footer";
import SelIcon from "./SelIcon";

function NewBot({ onNewBotClick, from }: any) {
  const selectedConnection: any = localStorage.getItem("activeConnection");
  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row justify-content-center px-5 py-5 text-center start-chat">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <img
                src="../assets/images/logo-light.png"
                className="img-fluid mb-4"
                alt="voteImg"
                style={{
                  marginLeft: "24px",
                  marginTop: "150px",
                }}
              />
            </div>
            {/* <div className="mb-5">
                Caucus ChatBot Tools helps you to manage your {from === "fb" ? 'FB' : 'FB & INSTA'} audience by
                providing multiple ways to contact your Political Campaign and
                start a conversation.
              </div> */}
            {/* {from !== "insta" ? */}
            <div
              className="col-md-12 col-lg-12 col-xl-12"
              style={{ marginTop: "12px" }}
            >
              {from !== "insta" && selectedConnection !== "nav-inst-tab" ? (
                <button
                  type="button"
                  onClick={() => {
                    localStorage.setItem("selectedConnection", "nav-fb-tab");
                    onNewBotClick();
                  }}
                  className="btn btn-primary rounded-pill new-chat"
                >
                  <div className="w-100" style={{ width: "100%" }}>
                    <img
                      src="../assets/images/chatbot.svg"
                      className="img-fluid"
                      alt="chatBotImg"
                    />
                    New FB ChatBot Tool
                  </div>
                </button>
              ) : (
                ""
              )}
              &nbsp;&nbsp;
              {from !== "fb" && selectedConnection !== "nav-fb-tab" ? (
                <button
                  type="button"
                  onClick={() => {
                    localStorage.setItem("selectedConnection", "nav-inst-tab");
                    onNewBotClick();
                  }}
                  className="btn btn-primary rounded-pill new-chat"
                >
                  <div className="w-100" style={{ width: "100%" }}>
                    <img
                      src="../assets/images/chatbot.svg"
                      className="img-fluid"
                      alt="chatBotImg"
                    />
                    New Insta ChatBot Tool
                  </div>
                </button>
              ) : (
                ""
              )}
            </div>
            {/* <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
              <a href="#" className="learmore">
                Learn more
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NewBot;
